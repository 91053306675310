import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/zetra/1.jpg"
import img2 from "../../images/zetra/2.jpg"

import img5 from "../../images/zetra/5.jpg"
import img6 from "../../images/zetra/6.jpg"
import img8 from "../../images/zetra/8.jpg"


// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;






export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    Zetra International is a financial service provider specialized in M&A.
                    <p/>
                    <a href="http://www.distylerie.com/" target="_blank">distylerie</a> and <LinkABOUT location={location}>I</LinkABOUT> created a weekender blog that shows the light side of Zetra while staying true to their values of cleverness and sophistication.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
                  Zetra International ist eine Finanzdienstleister spezialisiert auf M&A.
                  <p/>
                  <a href="http://www.distylerie.com/" target="_blank">distylerie</a> und <LinkABOUT location={location}>Ich</LinkABOUT> erschufen einen Weekender Blog, der die leichte Seite von Zetra zeigt und gleichzeitig ihre Unternehmenswerte hervorhebt.
                  <p/>
                  <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>


    </div>

    <Element name="test1"></Element>


    <img src={img1}/>





      <div className="comment">
        <English Language={location.search}>
              <Type>
              The blog posts cover a wide range of topics, from economics, history, psychology or natural sciences.
              </Type>
        </English>


         <Deutsch Language={location.search}>
              <Type>

                Die Blog Posts decken eine grosse Reichweite an  Themen ab, von Ökonomie über Geschichte und Psychologie bis Astrophysik.

              </Type>
        </Deutsch>
        
      </div>


      <img src={img2}/>







      <div className="comment">
        <English Language={location.search}>
              <Type>

              My role is thinking of new topics, writing the articles and to find visual metaphors with the illustrator.

              </Type>
        </English>


         <Deutsch Language={location.search}>
              <Type>

                Meine Aufgabe ist neue Themen zu finden, die Artikel zu schreiben und visuelle Metaphern mit dem Illustrator zu finden.

              </Type>
        </Deutsch>

      </div>

      <img src={img6}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>

              Each post loosely relates to finance while conveying a surprising thought or scientific fact.

              </Type>
        </English>


         <Deutsch Language={location.search}>
              <Type>

                Jeder Blog bezieht sich auf Umwegen auf die Finanzwelt und teilt einen überraschenden Gedanken oder kontraintuitive wissenschaftliche Erkenntnis mit.

              </Type>
        </Deutsch>

      </div>


      <img src={img5}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>

              Did you ever wonder why the west has been dominating the world since the middle ages?

              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

                Warum hat der Westen seit dem Mittelalter die Welt dominiert? 

              </Type>
        </Deutsch>

      </div>


      <img src={img8}/>



      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
